import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'custompipe'
})
export class CustompipePipe implements PipeTransform {

  transform(val: any, value: any): any {
    if (!val) return [];
    if (!value) return val;
    return val.filter((res: any) => {

      if (res.account_email) {
        return res.account_email.toLowerCase().includes(value);
      }
      else if (res.username) {
        return res.username.toLowerCase().includes(value);

      }
      else if (res.profile_title) {
        return res.profile_title.toLowerCase().includes(value);

      }
      else if (res.tag_name) {
        return res.tag_name.toLowerCase().includes(value);

      }
      else if (res.description) {
        return res.tag_name.toLowerCase().includes(value);

      }
      else {
        return res.username.toLowerCase().includes(value);

      }
    })
  }



}

// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'custompipe'
// })
// export class CustompipePipe implements PipeTransform {

//   transform(val: any, value: any): any {
//     if (!val) return [];
//     if (!value) return val;
//     const regexPattern = new RegExp(value.toLowerCase(), 'i');
//     return val.filter((res: any) => {
//       return Object.values(res).some((prop: any) => {
//         return typeof prop === 'string' && regexPattern.test(prop.toLowerCase());
//       });
//     });
//   }
// }
