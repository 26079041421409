

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { AddContentComponent } from '../add-content/add-content.component';
import { AddLinkComponent } from '../add-link/add-link.component';


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  highlyttoggle: boolean = false;
  currentRoute: any = '';
  selectedSocialInfo: any = {};
  selectedMemberCount: number = 0;
  imageBaseUrl: any;
  addNotesForm: FormGroup;
  profileIconArrayLength: any
  userInfo: any = {}
  userData: any
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<AddLinkComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private api: ApiService, private message: MessagehandlingService) {

    this.currentRoute = window.location.pathname
    this.addNotesForm = this.fb.group({
      highlight: '',
      content: '',
      title: this.data.block.name,
      description: '',
    })
  }

  ngOnInit(): void {
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }
  submitForm() {
    if (window.location.pathname === '/profile' || window.location.pathname === '/profile/content') {
      this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength')
      if (
        !this.addNotesForm.get('title')?.value ||
        !this.addNotesForm.get('content')?.value

      ) {
        this.message.errorMessage('Please fill in all required fields.', 2000);
        return;
      }
      let formData = new FormData();
      formData.append('highlight', this.addNotesForm.value.highlight == '' || this.addNotesForm.value.highlight == false ? '0' : '1')
      formData.append('content', this.addNotesForm.value.content)
      formData.append(' row_order', this.profileIconArrayLength + 1)
      formData.append('title', this.addNotesForm.value.title)
      formData.append('description', this.addNotesForm.value.highlight == true ? this.addNotesForm.value.description : '')
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)
    }
    if (window.location.pathname === '/members') {
      if (this.selectedMemberCount > 0) {
        if (
          !this.addNotesForm.get('title')?.value ||
          !this.addNotesForm.get('content')?.value

        ) {
          this.message.errorMessage('Please fill in all required fields.', 2000);
          return;
        }
        let formData = new FormData
        formData.append('highlight', this.addNotesForm.value.highlight == '' || this.addNotesForm.value.highlight == false ? '0' : '1')
        formData.append('content', this.addNotesForm.value.content)
        formData.append(' row_order', this.profileIconArrayLength + 1)
        formData.append('title', this.addNotesForm.value.title)
        formData.append('description', this.addNotesForm.value.highlight == true ? this.addNotesForm.value.description : '')
        formData.append('slug', this.selectedSocialInfo?.slug)
        formData.append('active', '')
        formData.append('type', 'primary')
        formData.append('user_id', sessionStorage.getItem('userIdArray') || '')
        this.api.saveLinksForMultipleProfile(formData).subscribe((res: any) => {
          this.dialog.closeAll()
          this.message.sucessMessage(res.msg, 2000)
          this.selectedMemberCount == 0
        })
      }
    }
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });

  }

  updateLink(formData: any) {
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })

  }

  get content() {

    return this.addNotesForm.get('content')!.value
  }

  // previewLink() {
  //  let notesLink='notes/'
   //  
  // this.router.navigate([`/notes/${btoa(this.selectedSocialInfo?.id)}`])
  // let link = `${notesLink}${btoa(this.selectedSocialInfo?.id)}`
  // window.open(link)
  // }
  previewLink() {
    const encodedId = btoa(this.selectedSocialInfo?.id);
    const notesRoute = `/notes/${encodedId}`;
    const newWindow = window.open('', '_blank'); 
    if (newWindow) {
      newWindow.location.href = notesRoute; 
    } else {
      console.error('Unable to open new window. Please check your browser settings.');
    }
  }
}