import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './common/guards/authguard.guard';
import { ContactRedirectComponent } from './components/contact-redirect/contact-redirect.component';
import { InviteAcceptedComponent } from './components/invite-accepted/invite-accepted.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NotesPreviewLinkComponent } from './components/notes-preview-link/notes-preview-link.component';

const routes: Routes = [
  { path: 'forgot-password', loadChildren: () => import('./components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'signup', loadChildren: () => import('./components/signup/signup.module').then(m => m.SignupModule) },
  { path: 'members', loadChildren: () => import('./components/members/members.module').then(m => m.MembersModule), canActivate: [AuthguardGuard] },
  { path: 'insights', loadChildren: () => import('./components/insights/insights.module').then(m => m.InsightsModule), canActivate: [AuthguardGuard] },
  //{ path: 'members', loadChildren: () => import('./components/members/members.module').then(m => m.MembersModule) },
  // { path: 'insights', loadChildren: () => import('./components/insights/insights.module').then(m => m.InsightsModule) },
  { path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) },
  
  { path: 'add-members', loadChildren: () => import('./components/members/add-members/add-members.module').then(m => m.AddMembersModule) },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'subteam', loadChildren: () => import('./components/subteam/subteam.module').then(m => m.SubteamModule), canActivate: [AuthguardGuard] },
  { path: 'profile', loadChildren: () => import('./components/members/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthguardGuard] },
  { path: 'integrations', loadChildren: () => import('./components/integrations/integrations.module').then(m => m.IntegrationsModule), canActivate: [AuthguardGuard] },
  { path: 'settings', loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthguardGuard] },
  { path: 'leads', loadChildren: () => import('./components/leads/leads.module').then(m => m.LeadsModule), canActivate: [AuthguardGuard] },
  { path: 'notifications', loadChildren: () => import('./components/notifications/notifications.module').then(m => m.NotificationsModule), canActivate: [AuthguardGuard] },
  { path: 'devices', loadChildren: () => import('./components/devices/devices.module').then(m => m.DevicesModule) },
  { path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) },

  { path: 'subscription',  loadChildren: () => import('./components/niha-subscription/niha-subscription.module').then(m => m.NihaSubscriptionModule) },
  {
    path: 'contact/:username',
    component: ContactRedirectComponent,
  },
  {
    path: 'notes/:id',
    component: NotesPreviewLinkComponent,
  },
  
  {
    path: 'inviteAccepted',
    component: InviteAcceptedComponent,
  },
  {
    path: 'reset_password/:email_token/:user_id',
    component: ResetPasswordComponent,
  },
  {
    path: 'updatePassword',
    component: UpdatePasswordComponent,
  },

 {path:'', redirectTo:'/login',pathMatch:'full'},
//  {
//   path:'subteam-profile/:id',component:SubteamProfileComponent
// },

// { path: 'subteam', loadChildren: () => import('./components/subteam/subteam.module').then(m => m.SubteamModule) },


  // { path: 'subteam', loadChildren: () => import('./components/subteam/subteam.module').then(m => m.SubteamModule) },
  // { path: 'profile', loadChildren: () => import('./components/members/profile/profile.module').then(m => m.ProfileModule) },
  // { path: 'integrations', loadChildren: () => import('./components/integrations/integrations.module').then(m => m.IntegrationsModule) },
  // { path: 'settings', loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule) },
  // { path: 'leads', loadChildren: () => import('./components/leads/leads.module').then(m => m.LeadsModule) },
  // { path: 'notifications', loadChildren: () => import('./components/notifications/notifications.module').then(m => m.NotificationsModule) },
  // { path: 'devices', loadChildren: () => import('./components/devices/devices.module').then(m => m.DevicesModule) },
  // {path:'**',redirectTo:'/login',pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
