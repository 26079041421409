import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { AddLinkComponent } from '../add-link/add-link.component';
import { BappComponent } from '../bapp/bapp.component';
import { BehanceComponent } from '../behance/behance.component';
import { CalenderComponent } from '../calender/calender.component';
import { ClubhouseComponent } from '../clubhouse/clubhouse.component';
import { CvComponent } from '../cv/cv.component';
import { DevianartComponent } from '../devianart/devianart.component';
import { DribbbleComponent } from '../dribbble/dribbble.component';
import { EmailComponent } from '../email/email.component';
import { EventsComponent } from '../events/events.component';
import { FaceTimeComponent } from '../face-time/face-time.component';
import { FacebookComponent } from '../facebook/facebook.component';
import { FoursquareComponent } from '../foursquare/foursquare.component';
import { GoogleMeetComponent } from '../google-meet/google-meet.component';
import { InstagramComponent } from '../instagram/instagram.component';
import { KikComponent } from '../kik/kik.component';
import { LinkComponent } from '../link/link.component';
import { LinkedinComponent } from '../linkedin/linkedin.component';
import { MeetupComponent } from '../meetup/meetup.component';
import { MessagesComponent } from '../messages/messages.component';
import { MessengerComponent } from '../messenger/messenger.component';
import { MicrosoftTeamsComponent } from '../microsoft-teams/microsoft-teams.component';
import { NetworkComponent } from '../network/network.component';
import { NotesComponent } from '../notes/notes.component';
import { PhoneComponent } from '../phone/phone.component';
import { PinterestComponent } from '../pinterest/pinterest.component';
import { QuoraComponent } from '../quora/quora.component';
import { QzoneComponent } from '../qzone/qzone.component';
import { RedditComponent } from '../reddit/reddit.component';
import { RenrenComponent } from '../renren/renren.component';
import { SkypeComponent } from '../skype/skype.component';
import { SnapchatComponent } from '../snapchat/snapchat.component';
import { TelegramComponent } from '../telegram/telegram.component';
import { TiktokComponent } from '../tiktok/tiktok.component';
import { TumblrComponent } from '../tumblr/tumblr.component';
import { TwitchComponent } from '../twitch/twitch.component';
import { TwitterComponent } from '../twitter/twitter.component';
import { UploadfileComponent } from '../uploadfile/uploadfile.component';
import { ViberComponent } from '../viber/viber.component';
import { WeiboComponent } from '../weibo/weibo.component';
import { WhatsappComponent } from '../whatsapp/whatsapp.component';
import { YoutubeComponent } from '../youtube/youtube.component';
import { ZoomComponent } from '../zoom/zoom.component';

@Component({
  selector: 'app-add-content',
  templateUrl: './add-content.component.html',
  styleUrls: ['./add-content.component.scss']
})
export class AddContentComponent implements OnInit {
  searchText: any
  userInfo: any = {}
  userData: any
  iconData: any = [{
    title: 'Social Media', icons: [
      { img: 'assets/images/facebook.png', name: 'Facebook' },
      { img: 'assets/images/linkedin.png', name: 'LinkedIn' },
      { img: 'assets/images/youtube.png', name: 'Youtube' },
      { img: 'assets/images/instagram1.png', name: 'Instagram' },
      { img: 'assets/images/tiktok.png', name: 'TikTok' },
      { img: 'assets/images/pinterest.png', name: 'Pinterest' }]
  }, {
    title: 'Quick Access buttons', icons: [
      { img: 'assets/images/calendar.png', name: 'Calendar' },
      { img: 'assets/images/file.png', name: 'File' },
      { img: 'assets/images/link.png', name: 'Link' },
      { img: 'assets/images/cv.png', name: 'CV' },
      { img: 'assets/images/b-App.png', name: 'B-App' }]
  }

  ];
  selectedMemberCount: any  //= 0;
  ImageBaseUrl: any;
  usersubscription: any
  constructor(public dialog: MatDialog, private api: ApiService, private message: MessagehandlingService, public dialogRef: MatDialogRef<AddContentComponent>) {

  }

  ngOnInit(): void {
    this.usersubscription = JSON.parse(sessionStorage.getItem('usersubscription') || '')

    let form = new FormData()
    form.append('acc_type', 'public')
    this.api.appIcons(form).subscribe((res: any) => {
      this.iconData = res.items
      this.ImageBaseUrl = this.api.baseSocialImageUrl
      this.userData = (sessionStorage.getItem('userinformation'))
      this.userInfo = JSON.parse(this.userData)
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addLink(data: any): void {
    if (data.pro != 0 && this.usersubscription == null) {
      this.message.errorMessage('You have to take subscription for this feature.', 2000)
    } else {
      switch (data.slug) {
        case 'bpay':
          this.message.errorMessage('Coming Soon......', 2000)
        break;
        case 'whatsApp':
          this.dialog.open(WhatsappComponent, {
            panelClass: ['cmn_modal', 'modal_sm'],
            width: '390px',
            data: { block: data },
          })
            .afterClosed()
            .subscribe((res) => {
              setTimeout(() => {
                this.getEditIcons()
                this.getActiveIcons()
              }, 2000);
            });
          break; 
        case 'customlink':
          this.dialog.open(LinkComponent, {
            panelClass: ['cmn_modal', 'modal_sm'],
            width: '390px',
            data: { block: data },
          })
            .afterClosed()
            .subscribe((res) => {
              setTimeout(() => {
                this.getEditIcons()
                this.getActiveIcons()
              }, 2000);
            });
          break;
        case 'wifi':
          this.dialog.open(NetworkComponent, {
            panelClass: ['cmn_modal', 'modal_sm'],
            width: '390px',
            data: { block: data },
          })
            .afterClosed()
            .subscribe((res) => {
              setTimeout(() => {
                this.getEditIcons()
                this.getActiveIcons()
              }, 2000);
            });
          break;
        case 'event':
          this.dialog.open(EventsComponent, {
            panelClass: ['cmn_modal', 'modal_sm'],
            width: '390px',
            data: { block: data },
          })
            .afterClosed()
            .subscribe((res) => {
              setTimeout(() => {
                this.getEditIcons()
                this.getActiveIcons()
              }, 2000);
            });
          break;
        case 'file':
          this.dialog.open(UploadfileComponent, {
            panelClass: ['cmn_modal', 'modal_sm'],
            width: '390px',
            data: { block: data },
          })
            .afterClosed()
            .subscribe((res) => {
              setTimeout(() => {
                this.getEditIcons()
                this.getActiveIcons()
              }, 2000);
            });
          break;
        case 'bapp':
          this.dialog.open(BappComponent, {
            panelClass: ['cmn_modal', 'modal_sm'],
            width: '390px',
            data: { block: data },
          })
            .afterClosed()
            .subscribe((res) => {
              setTimeout(() => {
                this.getEditIcons()
                this.getActiveIcons()
              }, 2000);
            });
          break;
        case 'phone':
          this.dialog.open(PhoneComponent, {
            panelClass: ['cmn_modal', 'modal_sm'],
            width: '390px',
            data: { block: data },
          })
            .afterClosed()
            .subscribe((res) => {
              setTimeout(() => {
                this.getEditIcons()
                this.getActiveIcons()
              }, 2000);
            });
          break;
        case 'messages':
          this.dialog.open(MessagesComponent, {
            panelClass: ['cmn_modal', 'modal_sm'],
            width: '390px',
            data: { block: data },
          })
            .afterClosed()
            .subscribe((res) => {
              setTimeout(() => {
                this.getEditIcons()
                this.getActiveIcons()
              }, 2000);
            });
          break;
        case 'notes':
          this.dialog.open(NotesComponent, {
            panelClass: ['cmn_modal', 'modal_sm'],
            width: '390px',
            data: { block: data },
          })
            .afterClosed()
            .subscribe((res) => {
              setTimeout(() => {
                this.getEditIcons()
                this.getActiveIcons()
              }, 2000);
            });
          break;

        default:
          this.dialog.open(AddLinkComponent, {
            panelClass: ['cmn_modal', 'modal_sm'],
            width: '390px',
            data: { block: data },
          })

            .afterClosed()
            .subscribe((res) => {
              //  
              setTimeout(() => {
                this.getEditIcons()
                this.getActiveIcons()
              }, 2000);
            });
          this.closeDialog();
      }
    }

  }

  getEditIcons() {
    let formData = new FormData();
    formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
    formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
    this.api.getEditIcons(formData).subscribe((res: any) => {
    })

  }

  getActiveIcons() {
    let formData = new FormData();
    formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
    formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
    this.api.getActiveIcons(formData).subscribe((res: any) => {
    })
  }

}
