<aside class="sidebarNav">


    <!-- LOGO -->
    <div class="navbar-brand text-center">
        <img src="assets/images/logo_login.png" alt="">
    </div>
    <!-- Sidebar menu list -->
    <div class="sidebarMenu_container pt-2" data-simplebar>
        <div class="sidebar_inner">
            <ul class="sidebar list-unstyled fs12 fontMed m-0">
                <!-- item -->
                <li class="listItem d-flex align-items-center" [routerLink]="['/members']"
                    [routerLinkActive]="'active'">
                    <i class="icon"><img src="assets/images/menu-person_black_24dp.svg" /></i>
                    <span>Members</span>
                </li>


                <!-- item -->
                <li *ngIf="userInfo?.role=='admin'" class="listItem d-flex align-items-center"
                    [routerLink]="['/subteam']" [routerLinkActive]="'active'">
                    <i class="icon"><img src="assets/images/menu-groups_black_24dp.svg" /></i>
                    <span>Subteams</span>
                </li>

                <!-- item -->
                <li class="listItem d-flex align-items-center" [routerLink]="['/leads']" [routerLinkActive]="'active'">
                    <i class="icon"><img src="assets/images/menu-contacts_black_24dp.svg" /></i>
                    <span>Leads</span>
                </li>

                <!-- item -->
                <li class="listItem d-flex align-items-center" [routerLink]="['/devices']"
                    [routerLinkActive]="'active'">
                    <i class="icon"><img src="assets/images/menu-contactless_black_24dp.svg" /></i>
                    <span>Devices</span>
                </li>

                <!-- item -->
                <li class="listItem d-flex align-items-center" [routerLink]="['/insights']"
                    [routerLinkActive]="'active'">
                    <i class="icon"><img src="../../assets/images/menu-assessment_black_24dp.svg" /></i>
                    <span>Insights</span>
                </li>

                <!-- item -->
                <li *ngIf="userInfo?.role=='admin'" class="listItem d-flex align-items-center"
                    [routerLink]="['/integrations']" [routerLinkActive]="'active'">
                    <i class="icon"><img src="../../assets/images/menu-electrical_services_black_24dp.svg" /></i>
                    <span>Integrations</span>
                </li>

                <!-- item -->
                <li *ngIf="userInfo?.role=='admin'" class="listItem d-flex align-items-center"
                    [routerLink]="['/notifications']" [routerLinkActive]="'active'">
                    <i class="icon"><img src="../../assets/images/menu-notifications_black_24dp.svg" /></i>
                    <span>Notifications</span>
                </li>
            </ul>

            <div class="sidebarAlert alert alert-light my-3 border fade show" role="alert">
                <p class="fs11 mb-1"><strong>Members logged in</strong></p>
                <p class="fs11 mb-0 text_primary">{{memberLoggedIn | percent }}</p>
                <div class="progress">
                    <div class="progress-bar rounded-pill primaryBg" role="progressbar"
                        [ngStyle]="{'width': percentagevalue+'%'}" aria-valuenow="100" aria-valuemin="0"
                        aria-valuemax="100"></div>
                </div>
                <p class="fs9 mt-1 mb-2">{{count}}/{{total}} members have logged in</p>
                <span class="fs9 fw-bold d-block text-end text_light" type="button" data-bs-dismiss="alert"
                    aria-label="Close">Dismiss</span>
                <button *ngIf="count!=total"
                    class="btn whiteBg border rounded-2 text_secondary px-3 py-1 fs9 fontMed px-4"
                    (click)="sendInvite()">Send invites</button>
            </div>

            <ul class="sidebar list-unstyled fs12 fontMed m-0">

                <li class="listItem d-flex align-items-center" [routerLink]="['/subscription']"
                [routerLinkActive]="'active'">
                <i class="icon"><img src="assets/images/my-subscription.svg" /></i>
                <span>Subscription</span>
            </li>
                <!-- item -->
                <li class="listItem d-flex align-items-center" [routerLink]="['/settings']"
                    [routerLinkActive]="'active'">
                    <i class="icon"><img src="assets/images/menu-settings_black_24dp.svg" /></i>
                    <span>Settings</span>
                </li>

              

                <!-- item -->
                <li class="listItem d-flex align-items-center user_img" (click)="profile()">
                    <i class="icon_user rounded-pill d-flex align-items-center justify-content-center">
                        <!-- // added on 29 nov 2022 start -->

                        <img *ngIf="userInfo?.photo" class="icon_user rounded-pill" [src]="imageUrl + userInfo.photo"
                            alt="">

                        <img *ngIf="!userInfo?.photo" class="rounded-pill" src="../../assets/images/user_icon.svg"
                            alt=""></i>
                    <div class="user_info text-truncate ms-1">
                        <!-- // added on 29 nov 2022 start -->
                        <span>{{userInfo?.account_email}}</span>
                        <p class="m-0 text_light fs11 text-uppercase">{{userInfo?.role}}</p>
                    </div>
                </li>

                <!-- item -->
                <li class="listItem d-flex align-items-center primaryBgOpacity10 rounded-3" (click)="referral()">
                    <i class="icon"><img src="assets/images/menu-redeem_black_24dp.svg" /></i>
                    <span>Get a free month</span>
                </li>
            </ul>
        </div>
    </div>
</aside>